<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <el-button
            @click="$router.push('/administration/group/create')"
            type="primary"
        >
          {{ $t('system.create') }}
        </el-button>
      </div>
    </div>
    <div class="view">
      <div class="view-body">
        <static-table
            :data="model"
            :options="options"
            :params="params"
            :thead="thead"
            @cancelEdit="cancelEdit"
            @confirmEdit="confirmEdit"
            @editElement="editElement"
            @removeElement="removeElement"
            @sortChange="sortChange"
        ></static-table>
        <el-card
            class="pagination-container mt-3"
            shadow="never"
        >
          <el-pagination
              :current-page="params.page"
              :page-size="params.per_page"
              :page-sizes="[10, 30, 50, 100, 300]"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </el-card>
        <!--<ab-table :source="source" :thead="thead" :options="options"></ab-table>-->
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import swal from 'sweetalert2';
import notification from '../../notification/notify';
// import ABTable from '../../components/ABtable/index.vue';
import StaticTable from '../../components/ABtable/StaticTable.vue';

export default {
  name: 'GroupIndex',
  data() {
    return {
      title: 'Groups',
      source: 'group',
      create: 'group/create',
      thead: [
        {
          label: 'ID',
          key: 'id',
          sort: false,
        },
        {
          label: 'group.name',
          key: 'name',
          sort: true,
          edit: true,
          link: true,
          url: '/administration/group/',
        },
        {
          label: 'group.users_count',
          key: 'users_count',
          sort: true,
        },
        {
          label: '',
          key: 'action',
          sort: false,
          type: 'action',
        },
      ],
      options: {
        paginate: true,
        default_sort: 'name',
        inline_edit: true,
        row_click: false,
      },
      params: {
        current_page: '',
        from: '',
        to: '',
        last_page: '',
        column: 'created_at',
        direction: 'desc',
        per_page: 10,
        page: 1,
      },
      model: [],
      total: 0,
    };
  },
  components: {
    // 'ab-table': ABtable,
    StaticTable,
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      var vm = this;
      vm.url = this.buildURL();
      await axios.get(vm.url)
          .then(function (response) {
            vm.model = response.data.data;
            if (typeof response.data.total !== 'undefined') {
              vm.total = response.data.total;
            } else {
              if (vm.options.paginate) {
                vm.params.current_page = parseInt(response.data.meta.current_page);
                vm.params.from = parseInt(response.data.meta.from);
                vm.params.last_page = parseInt(response.data.meta.last_page);
                vm.params.per_page = parseInt(response.data.meta.per_page);
                vm.params.to = parseInt(response.data.meta.to);
                vm.total = parseInt(response.data.meta.total);
              }
            }
            vm.newList = vm.model.slice();
          })
          .catch(function () {
          });
    },
    buildURL() {
      const p = this.params;
      if (this.options.paginate) {
        return `${this.source}?column=${p.column}&direction=${p.direction}&per_page=${p.per_page}&page=${p.page}`;
      } else {
        return `${this.source}?column=${p.column}&direction=${p.direction}`;
      }
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.fetchData();
    },
    handleSizeChange(val) {
      this.params.per_page = val;
      this.fetchData();
    },
    next() {
      if (this.model.next_page_url) {
        this.params.page++;
        this.fetchData();
      }
    },
    prev() {
      if (this.model.prev_page_url) {
        this.params.page--;
        this.fetchData();
      }
    },
    sortChange(column) {
      if (column === this.params.column) {
        if (this.params.direction === 'desc') {
          this.params.direction = 'asc';
        } else {
          this.params.direction = 'desc';
        }
      } else {
        this.params.column = column;
        this.params.direction = 'asc';
      }
      this.fetchData();
    },
    editElement(row) {
      row.edit = true;
      row.originalName = row.name;
    },
    confirmEdit(row) {
      let vm = this;
      if (vm.options.inline_edit) {
        let formData = new FormData();
        for (let property in row) {
          if (row.hasOwnProperty(property)) {
            if (row['hours'] === '') {
              row['hours'] = '000:00';
            }
            if (row[property] instanceof Date) {
              row[property] = moment(row[property]).format('YYYY-MM-DD HH:mm:ss');
            }
            formData.append(property, JSON.stringify(row[property]));
          }
        }
        const config = {headers: {'Content-Type': `multipart/form-data`}};
        axios.post(`${vm.source}/${row.id}`, formData, config)
            .then(function (response) {
              if (response.data.saved) {
                row.edit = false;
                row.originalName = row.name;
                if (response.data.model) {
                  row.user = response.data.model.user;
                  row.activity = response.data.model.activity;
                }
                notification.notify(
                    vm.$t('notify.success'),
                    response.data.message,
                    'success');
              } else {
                notification.notify(
                    vm.$t('notify.error'),
                    response.data.message,
                    'error');
              }
            })
            .catch(function (error) {
              if (typeof error !== 'undefined') {
                notification.notify(
                    vm.$t('notify.error'),
                    error.response.data.errors.name[0],
                    'error');
              }
            });
      } else {
        row.edit = false;
        row.originalName = row.name;
      }
    },
    removeElement: function (element) {
      let vm = this;
      swal.fire({
        title: vm.$t('system.remove'),
        text: vm.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
      }).then(function (resp) {
        if (resp.value) {
          if (element.id) {
            axios.delete(`${vm.source}/${element.id}`)
                .then(function (response) {
                  if (vm.model) {
                    vm.model.splice(vm.model.indexOf(element), 1);
                  } else if (vm.newList) {
                    vm.newList.splice(vm.newList.indexOf(element), 1);
                  } else if (vm.data) {
                    vm.data.splice(vm.data.indexOf(element), 1);
                  }
                  vm.$emit('refresh');
                  notification.notify(
                      vm.$t('notify.success'),
                      response.data.message,
                      'success');
                })
                .catch(function (error) {
                  notification.notify(
                      vm.$t('notify.error'),
                      error.response.data.error,
                      'error');
                });
          }
        }
      });
    },
    cancelEdit(row) {
      row.name = row.originalName;
      row.edit = false;
    },
  },
};
</script>
